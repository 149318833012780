<template>
  <CRow align-horizontal="center">
    <CCol sm="4">
      <CCard>
        <div class="loader" v-if="isLoading">
          <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
        </div>

        <CCardHeader>
          {{ $t("admin.intervals.title") }}

          <CSpinner
            color="primary"
            class="float-right spinner"
            v-if="isUpdating"
          />
        </CCardHeader>
        <CCardBody>
          <ValidationError :errors="errors" />
          <CRow>
            <CCol sm="5">
              <label>{{ $t("admin.intervals.time_span") }}</label>
            </CCol>
            <CCol sm="5">
              <label>{{ $t("admin.intervals.orders") }}</label>
            </CCol>
            <CCol sm="2">
              <label>{{ $t("admin.intervals.colour") }}</label>
            </CCol>
          </CRow>
          <CRow v-for="interval in intervals" :key="interval.id">
            <CCol sm="5">
              <label>{{ interval.from }} — {{ interval.to }}</label>
            </CCol>
            <CCol sm="5">
              <CInput
                :value="interval.orders"
                @input="update({ orders: $event }, interval.id)"
              />
            </CCol>
            <CCol sm="2">
              <CInput
                type="color"
                :value="interval.colour"
                @input="update({ colour: $event }, interval.id)"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ValidationError from "@/components/admin/ValidationError";
import debounce from "lodash.debounce";

export default {
  components: {
    ValidationError
  },

  data() {
    return {
      isLoading: true,
      isUpdating: false,
      errors: [],
      intervals: []
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      }
    }
  },

  methods: {
    getData() {
      this.isLoading = true;
      this.$http
        .get("/api/admin/intervals", {
          params: {
            is_pickup: this.$route.meta.pickup || false
          }
        })
        .then(response => {
          this.intervals = response.data.data;
          this.isLoading = false;
        });
    },
    update: debounce(function(payload, id) {
      this.isUpdating = true;
      this.errors = [];

      this.$http
        .patch(`/api/admin/intervals/${id}`, payload)
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isUpdating = false));
    }, 500)
  },

  metaInfo() {
    return {
      title: this.$t("admin.intervals.title")
    };
  }
};
</script>

<style scoped>
.spinner {
  position: fixed;
  right: 20px;
  bottom: 20px;
}
</style>
