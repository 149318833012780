<template>
  <div>
    <CRow>
      <CCol sm="2">
        <CInput :label="$t('admin.search_by_user_id')" v-model="search.id" />
      </CCol>
      <CCol sm="3">
        <CInput
          :label="$t('admin.search_by_user_name')"
          v-model="search.full_name"
        />
      </CCol>
      <CCol sm="3">
        <CInput :label="$t('admin.search_by_email')" v-model="search.email" />
      </CCol>
      <CCol sm="2">
        <CInput :label="$t('admin.search_by_phone')" v-model="search.phone" />
      </CCol>
      <CCol sm="2" class="col-sm-2 d-flex align-items-center mt-2">
        <CButton
          color="primary"
          square
          block
          class="float-right"
          @click="getData()"
        >
          {{ $t("search.title") }}
        </CButton>
      </CCol>
    </CRow>
    <CCard>
      <CCardHeader>
        {{ $t("admin.users.title") }}
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :loading="isLoading"
          :items="items"
          :fields="fields"
          :noItemsView="{
            noResults: $t('admin.no_results'),
            noItems: $t('admin.no_items')
          }"
          border
          striped
          hover
        >
          <template #is_phone_verified="{item}">
            <td class="py-2 text-center">
              <CIcon name="cilCheckAlt" v-if="item.is_phone_verified" />
            </td>
          </template>

          <template #show_actions="{item}">
            <td class="py-2 text-center">
              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="verifyPhone(item.id)"
                v-if="!item.is_phone_verified"
              >
                <CIcon name="cilCheckAlt" />
              </CButton>

              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                :to="{
                  name: 'admin.users.neworder',
                  params: { user: item.id }
                }"
              >
                <CIcon name="cilBasket" />
              </CButton>

              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                @click="impersonate(item.id)"
              >
                <CIcon name="cilWalk" />
              </CButton>

              <CButton
                color="primary"
                square
                size="sm"
                class="mr-1"
                :to="{
                  name: 'admin.users.show',
                  params: { user: item.id }
                }"
              >
                <CIcon name="cilPencil" />
              </CButton>

              <CButton
                class="mr-1"
                color="danger"
                size="sm"
                square
                @click="deleteUser(item.id)"
              >
                <CIcon name="cilTrash"></CIcon>
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage="pagination.current_page"
          :pages="pagination.last_page"
          align="center"
          @update:activePage="getData($event)"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      items: [],
      pagination: {},
      search: {
        id: null,
        full_name: null,
        email: null,
        phone: null
      },
      fields: [
        { key: "id", label: "ID", _style: "width: 100px" },
        { key: "full_name", label: this.$t("admin.users.full_name") },
        { key: "email", label: this.$t("login.email") },
        { key: "phone", label: this.$t("register.phone") },
        { key: "orders_count", label: this.$t("admin.users.total_orders") },
        { key: "orders_total", label: this.$t("admin.users.total_sales") },
        { key: "registered_at", label: this.$t("admin.users.registered_at") },
        { key: "last_ordered_at", label: this.$t("admin.users.last_order_at") },
        { key: "is_phone_verified", label: this.$t("admin.users.is_verified") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 180px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData(page = 1) {
      this.isLoading = true;
      return this.$http
        .get("/api/admin/users", { params: { page, ...this.search } })
        .then(response => {
          this.items = response.data.data;
          this.pagination = response.data.meta;
          this.isLoading = false;
        });
    },
    async verifyPhone(id) {
      await this.$http.post(`/api/admin/users/${id}/verify-phone`);
      await this.getData();
    },
    async impersonate(id) {
      if (confirm(`Are you sure you want to impersonate the user #${id}?`)) {
        await this.$http.post(`/api/admin/users/${id}/impersonate`);

        location.reload();
      }
    },
    deleteUser(id) {
      if (confirm("Are you sure you want to delete this user?")) {
        this.isLoading = true;
        this.$http
          .delete(`/api/admin/users/${id}`)
          .catch(error => {
            alert(error.response.data.message);
          })
          .finally(() => this.getData(this.pagination.current_page));
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.users.title")
    };
  }
};
</script>
