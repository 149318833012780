<template>
  <div id="modal_time" class="modal_time">
    <div class="content_time__modal">
      <button class="close__modal" @click="$emit('close-popup')"></button>
      <h3 class="title_modal__time">
        {{ $t("delivery.delivery_time") }}
      </h3>
      <div class="btn__login-register">
        <a
          :class="['btn-login', { active: !isTomorrow }]"
          @click="isTomorrow = false"
        >
          {{ $t("delivery.today") }}
        </a>
        <a
          :class="['btn-register', { active: isTomorrow }]"
          @click="isTomorrow = true"
        >
          {{ $t("delivery.tomorrow") }}
        </a>
      </div>

      <template v-for="(interval, index) in intervals">
        <div v-if="interval.available" :key="index" class="radio__time_modal">
          <input
            type="radio"
            :id="'interval-' + index"
            class="item_time__modal"
            :value="interval"
            v-model="selectedInterval"
          />
          <label :for="'interval-' + index">
            {{ formatInterval(interval) }}
          </label>
        </div>
        <div v-else :key="index" class="radio__time_modal maximum">
          {{ formatInterval(interval) }}
          <span>{{ $t("interval_is_full") }}</span>
        </div>
      </template>
      <div class="btn_adress">
        <button
          id="btn-pocket"
          class="nav__btn active"
          @click="$emit('close-popup')"
        >
          {{ $t("close") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatInterval } from "@/helpers";

export default {
  data() {
    return {
      isTomorrow: false,
      selectedInterval: null
    };
  },

  watch: {
    isTomorrow: function() {
      this.setSelectedInterval();
    },
    selectedInterval: function() {
      this.setDeliveryTimes();
    }
  },

  created() {
    this.prepare();
  },

  computed: {
    ...mapState("cart", ["cart"]),
    intervals() {
      return this.isTomorrow
        ? this.cart.delivery_intervals.tomorrow
        : this.cart.delivery_intervals.today;
    }
  },

  methods: {
    formatInterval(interval) {
      return formatInterval(interval.from, interval.to);
    },
    prepare() {
      this.isTomorrow =
        this.cart.delivery_intervals.today.find(
          interval => interval.available
        ) === undefined &&
        this.cart.delivery_intervals.tomorrow.find(
          interval => interval.available
        ) !== undefined;
      this.setSelectedInterval();
      this.setDeliveryTimes();
    },
    setSelectedInterval() {
      this.selectedInterval =
        this.intervals.find(interval => interval.available) || null;
    },
    setDeliveryTimes() {
      const interval = this.selectedInterval;
      this.$emit("set-deliver-from", interval != null ? interval.from : null);
      this.$emit("set-deliver-to", interval != null ? interval.to : null);
      this.$emit("set-period", interval != null ? interval.period : null);
      this.$emit("set-is-tomorrow", interval != null ? this.isTomorrow : null);
    }
  }
};
</script>

<style scoped>
.inactive {
  margin-left: 35px;
  color: #a9a9a9;
  padding: 10px 0;
}
</style>
