<template>
  <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
    {{ errors.message }}
    <ul>
      <li v-for="(error, field) in errors.errors" :key="field">
        <template v-if="error[0] === Object(error[0])">
          <div v-for="(nested_error, n_field) in error[0]" :key="n_field">
            {{ nested_error }}
          </div>
        </template>
        <div v-else>{{ error[0] }}</div>
      </li>
    </ul>
  </CAlert>
</template>

<script>
export default {
  props: ["errors"]
};
</script>
