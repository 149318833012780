<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{
        $route.meta.edit
          ? $t("admin.couriers.edit.title")
          : $t("admin.couriers.create.title")
      }}

      <CButton
        color="primary"
        square
        size="sm"
        class="float-right"
        @click="store"
      >
        {{ $t("buttons.save") }}
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
        {{ errors.message }}
        <ul>
          <li v-for="(error, field) in errors.errors" :key="field">
            {{ error[0] }}
          </li>
        </ul>
      </CAlert>
      <CRow>
        <CCol sm="4">
          <CInput :label="$t('admin.couriers.name')" v-model="form.name" />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.couriers.password')"
            type="password"
            v-model="form.password"
          />
        </CCol>
        <CCol sm="4">
          <CInput
            :label="$t('admin.couriers.colour')"
            type="color"
            v-model="form.colour"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      errors: [],
      form: {
        name: "",
        password: "",
        colour: "#000000"
      }
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      if (this.$route.meta.edit) {
        this.isLoading = true;

        this.$http
          .get(`/api/admin/couriers/${this.$route.params.id}`)
          .then(response => {
            this.form = response.data.data;

            this.isLoading = false;
          });
      }
    },
    store() {
      this.isLoading = true;
      this.errors = [];

      const url = this.$route.meta.edit
        ? `/api/admin/couriers/${this.$route.params.id}`
        : `/api/admin/couriers`;
      const method = this.$route.meta.edit ? "put" : "post";
      this.$http[method](url, this.form)
        .then(() =>
          this.$router.push({
            name: "admin.couriers",
            params: { id: this.$route.params.id }
          })
        )
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    }
  },

  metaInfo() {
    return {
      title: this.$route.meta.edit
        ? this.$t("admin.couriers.edit.title")
        : this.$t("admin.couriers.create.title")
    };
  }
};
</script>
