<template>
  <CModal
    title="Alter user balance"
    color="primary"
    :show.sync="show"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton color="primary" square class="float-right" @click="update">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <label>{{ $t("admin.users.show.balances.mode") }}</label>
        <CTabs
          variant="pills"
          :active-tab="activeTab"
          @update:activeTab="setMode"
        >
          <CTab title="Add" />
          <CTab title="Subtract" />
        </CTabs>
      </CCol>

      <CCol sm="12">
        <label>{{ $t("balance.method") }}</label>
        <CSelect :options="methods" :value.sync="form.method" />
      </CCol>

      <CCol sm="12">
        <CInput
            :label="$t('balance.amount')"
            type="number"
            v-model="form.amount"
        />
      </CCol>
      <CCol sm="12">
        <CInput
            :label="$t('balance.comment')"
            type="text"
            v-model="form.comment"
        />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
export default {
  props: ["user", "show"],

  data() {
    return {
      isLoading: false,
      errors: [],
      methods: [],
      methodsAdd: [
        {
          label: "Cash",
          value: "cash"
        },
        {
          label: "Ticket for restaurant",
          value: "ticket"
        },
        {
          label: "Bonus",
          value: "bonus"
        }
      ],
      methodsSub: [
        {
          label: "Refund",
          value: "refund"
        }
      ],
      activeTab: 0,
      form: {
        method: null,
        amount: 0,
        comment:'',
        add: true
      }
    };
  },

  watch: {
    show: function(show) {
      if (show) {
        this.methods = this.methodsAdd;
        if (this.methods.length > 0) {
          this.form.method = this.methods[0].value;
        }
      }
    }
  },

  methods: {
    update() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .post(`/api/admin/users/${this.user.id}/balances`, this.form)
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    setMode(index) {
      this.activeTab = index;
      if(index === 0) this.methods =this.methodsAdd;
      else this.methods = this.methodsSub;
      if (this.methods.length > 0) {
        this.form.method = this.methods[0].value;
      }
      this.form.add = index === 0;
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
