<template>
  <div>
    <CRow>
      <CCol sm="3">
        <label>{{ $t("admin.date") }}</label>
        <v-date-picker
          timezone="UTC"
          v-model="search.range"
          :model-config="modelConfig"
          is-range
          @dayclick="getData()"
        >
          <template #default="{ inputValue, togglePopover }">
            <CInput
              :value="`${inputValue.start} — ${inputValue.end}`"
              @click="togglePopover"
              readonly
            />
          </template>
        </v-date-picker>
      </CCol>
      <CCol sm="2">
        <CInput
          :label="$t('admin.search_by_order_id')"
          v-model="search.order_id"
        />
      </CCol>
      <CCol sm="2">
        <CInput
          :label="$t('admin.search_by_restaurant')"
          v-model="search.restaurant"
        />
      </CCol>
      <CCol sm="3">
        <CInput
          :label="$t('admin.search_by_user_name')"
          v-model="search.user_name"
        />
      </CCol>
      <CCol sm="2" class="col-sm-2 d-flex align-items-center mt-2">
        <CButton
          color="primary"
          square
          block
          class="float-right"
          @click="getData()"
        >
          {{ $t("search.title") }}
        </CButton>
      </CCol>
    </CRow>
    <CCard>
      <CCardHeader>
        {{ $t("admin.orders.title") }}
        <CButton
            v-if="$route.meta.pickup === false"
            class="mr-1"
            color="primary"
            size="sm"
            square
            @click="finishAll()"
        >
          {{ $t("admin.finish_all") }}
        </CButton>
        <CButton
            v-if="$route.meta.pickup === false"
            class="mr-1"
            color="primary"
            size="sm"
            square
            @click="finishLUNCH()"
        >
          {{ $t("admin.finish_lunch") }}
        </CButton>
        <CButton
            v-if="$route.meta.pickup === false"
            class="mr-1"
            color="primary"
            size="sm"
            square
            @click="finishDINNER()"
        >
          {{ $t("admin.finish_dinner") }}
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :loading="isLoading"
          :items="items"
          :fields="fields"
          :noItemsView="{
            noResults: $t('admin.no_results'),
            noItems: $t('admin.no_items')
          }"
          border
          striped
          hover
        >
          <template #show_user="{item}">
            <td class="py-2">
              {{ item.user.full_name }}
            </td>
          </template>

          <template #show_payment="{item}">
            <td class="py-2">
              {{
                $t("admin.orders.payment.info", {
                  total: item.invoice.total,
                  tips: item.invoice.tips,
                  method: item.invoice.type,
                  trans_id: item.invoice.transaction_id,
                  delivery_fee: item.invoice.delivery_fee,
                  pickup_discount: item.invoice.pickup_discount
                })
              }}
              <span v-if="item.invoice.use_balance > 0"
                ><br />{{ $t("cart.use_delovery_balance") }} :
                {{ item.invoice.use_balance }} &euro;</span
              >
              <span v-else-if="item.invoice.use_balance < 0"
                ><br />{{ $t("cart.pay_off_delovery_balance") }} :
                {{ -item.invoice.use_balance }} &euro;</span
              >
              <span v-if="item.invoice.amount_pay > 0"
                ><br />{{ $t("admin.orders.payment.amount_payed") }} :
                {{ item.invoice.amount_pay }} &euro;
              </span>
            </td>
          </template>

          <template #show_address="{item}">
            <td class="py-2">
              {{ item.address.short }}
            </td>
          </template>

          <template #processed_at="{item}">
            <td class="py-2">
              {{ item.invoice.processed_at }}
            </td>
          </template>

          <template #show_actions="{item}">
            <td class="py-2 text-center">
              <CButton
                :to="{
                  name: 'admin.orders.show',
                  params: { order: item.id }
                }"
                title="Edit"
                class="mr-1"
                color="primary"
                size="sm"
                square
              >
                <CIcon name="cilPencil" />
              </CButton>
              <CButton
                title="change interval"
                class="mr-1"
                color="primary"
                size="sm"
                square
                @click="openIntervalsModal(item.id)"
              >
                <CIcon name="cilClock" />
              </CButton>
              <CButton
                title="finish"
                v-if="item.status !== 'finished'"
                class="mr-1"
                color="primary"
                size="sm"
                square
                @click="finish(item)"
              >
                <CIcon name="cilFlagAlt" />
              </CButton>
              <CButton
                title="cancel"
                color="danger"
                square
                size="sm"
                class="mr-1"
                @click="cancel(item.id)"
                v-if="item.status !== 'canceled' && (new Date(item.delivery)) > dateMinus7"
              >
                <CIcon name="cilXCircle" />
              </CButton>
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage="pagination.current_page"
          :pages="pagination.last_page"
          align="center"
          @update:activePage="getData($event)"
        />
        <ChangeOrderInterval
          :order="activeOrderId"
          @closed="closeIntervalsModal"
          @updated="getData"
        />
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import ChangeOrderInterval from "@/components/management/ChangeOrderInterval";

export default {
  components: {
    ChangeOrderInterval
  },

  data() {
    return {
      isLoading: true,
      dateMinus7: '',
      items: [],
      activeOrderId: null,
      pagination: {},
      modelConfig: {
        start: {
          timeAdjust: "00:00:00"
        },
        end: {
          timeAdjust: "23:59:59"
        }
      },
      search: {
        range: {
          start: new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate()
            )
          ),
          end: new Date(
            Date.UTC(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              23,
              59,
              59,
              999
            )
          )
        },
        order_id: null,
        restaurant: null,
        user_name: null
      },
      fields: [
        { key: "id", label: "ID", _style: "width: 70px" },
        { key: "show_user", label: this.$t("admin.orders.user") },
        { key: "restaurants", label: this.$t("admin.orders.restaurants") },
        { key: "show_payment", label: this.$t("admin.orders.payment.title") },
        {
          key: "show_address",
          label: this.$t("admin.orders.address"),
          _style: "width: 150px"
        },
        { key: "deliver_from", label: this.$t("admin.orders.deliver_from") },
        { key: "deliver_to", label: this.$t("admin.orders.deliver_to") },
        { key: "status", label: this.$t("admin.orders.status") },
        { key: "processed_at", label: this.$t("admin.orders.processed_at") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 190px"
        }
      ]
    };
  },

  computed: {
    formattedSearch() {
      const payload = { ...this.search };

      payload.from = payload.range.start.toISOString();
      payload.to = payload.range.end.toISOString();
      delete payload.range;

      return payload;
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getData();
      }
    }
  },

  methods: {
    getData(page = 1) {
      this.isLoading = true;
      let date7 = new Date();
      date7.setDate(date7.getDate()-7);
      this.dateMinus7 = date7;

      let params = { page, ...this.formattedSearch };
      if (this.$route.meta.pickup !== undefined) {
        params.is_pickup = this.$route.meta.pickup;
      }
      if (this.$route.meta.with_comments !== undefined) {
        params.with_comments = this.$route.meta.with_comments;
      }
      if (this.$route.meta.canceled !== undefined) {
        params.canceled = this.$route.meta.canceled;
      }
      if (this.$route.meta.cash !== undefined) {
        params.cash = this.$route.meta.cash;
      }
      this.$http.get("/api/admin/orders", { params }).then(response => {
        this.items = response.data.data;
        this.pagination = response.data.meta;
        this.isLoading = false;
      });
    },
    finish(order) {
      if (confirm(`Are you sure you want to finish the order #${order.id}?`)) {
        if (
          order.is_early_for_delivery &&
          !confirm(this.$t("admin.orders.too_early_for_delivery"))
        ) {
          return;
        }

        this.isLoading = true;
        this.$http
          .put(`/api/admin/orders/${order.id}`, { status: "finished" })
          .then(() => this.getData());
      }
    },
    finishAll() {
      if (!confirm(`Are you sure you want to finish all orders?`)) {
        return;
      }

      this.isLoading = true;
      this.$http
          .put(`/api/admin/orders/finishall`, { status: "finished" })
          .then(() => this.getData());
    },
    finishLUNCH() {
      if (!confirm(`Are you sure you want to finish LUNCH orders?`)) {
        return;
      }

      this.isLoading = true;
      this.$http
          .put(`/api/admin/orders/finishlunch`, { status: "finished" })
          .then(() => this.getData());
    },
    finishDINNER() {
      if (!confirm(`Are you sure you want to finish DINNER orders?`)) {
        return;
      }

      this.isLoading = true;
      this.$http
          .put(`/api/admin/orders/finishdinner`, { status: "finished" })
          .then(() => this.getData());
    },
    cancel(order) {
      if (confirm(`Are you sure you want to cancel the order #${order}?`)) {
        this.isLoading = true;
        this.$http
          .put(`/api/admin/orders/${order}`, { status: "canceled" })
          .then(() => this.getData());
      }
    },
    openIntervalsModal(id) {
      this.activeOrderId = id;
    },
    closeIntervalsModal() {
      this.activeOrderId = null;
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.orders.title")
    };
  }
};
</script>
