<template>
  <CModal
    :show.sync="show"
    color="primary"
    title="Edit user info"
    @update:show="close"
  >
    <template #footer>
      <h5 class="modal-title">
        <CButton class="float-right" color="primary" square @click="update">
          {{ $t("buttons.save") }}
        </CButton>
      </h5>
    </template>

    <div v-if="isLoading" class="loader">
      <CSpinner color="primary" grow style="width:4rem; height:4rem;" />
    </div>

    <CAlert v-if="Object.entries(errors).length" :fade="false" color="danger">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <CInput
          v-model="form.first_name"
          :label="$t('register.first_name')"
          type="text"
        />
      </CCol>
      <CCol sm="12">
        <CInput
          v-model="form.last_name"
          :label="$t('register.last_name')"
          type="text"
        />
      </CCol>
      <CCol sm="12">
        <CInput v-model="form.email" :label="$t('login.email')" type="email" />
      </CCol>
      <CCol sm="12">
        <CInput v-model="form.phone" :label="$t('register.phone')" type="tel" />
      </CCol>
    </CRow>
  </CModal>
</template>

<script>
export default {
  props: ["user", "show"],

  data() {
    return {
      isLoading: false,
      errors: [],
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      }
    };
  },

  watch: {
    show: function(show) {
      if (show) {
        this.form.first_name = this.user.first_name;
        this.form.last_name = this.user.last_name;
        this.form.email = this.user.email;
        this.form.phone = this.user.phone;
      }
    }
  },

  methods: {
    update() {
      this.isLoading = true;
      this.errors = [];

      this.$http
        .put(`/api/admin/users/${this.user.id}`, this.form)
        .then(() => {
          this.close();
          this.$emit("updated");
        })
        .catch(error => (this.errors = error.response.data))
        .finally(() => (this.isLoading = false));
    },
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit("closed");
    }
  }
};
</script>
