<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("admin.orders.show.title") }}

      <CSpinner color="primary" class="float-right spinner" v-if="isUpdating" />
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="6">
          <CCard>
            <CCardHeader>{{ $t("admin.orders.general") }}</CCardHeader>
            <CCardBody>
              <table v-if="order && order.address && order.invoice">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{{ order.id }}</td>
                  </tr>
                  <tr>
                    <td>Order type</td>
                    <td>{{ order.is_mobile ? "Mobile" : "Site" }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.processed_at") }}</td>
                    <td>{{ order.invoice.processed_at }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.deliver_from") }}</td>
                    <td>{{ order.deliver_from }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.deliver_to") }}</td>
                    <td>{{ order.deliver_to }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.address") }}</td>
                    <td>{{ order.address.short }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.address_comment") }}</td>
                    <td>
                      <CTextarea
                        v-model="order.address.comment"
                        @input="updateAddressComment"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="6">
          <CCard>
            <CCardHeader>{{ $t("admin.orders.user") }}</CCardHeader>
            <CCardBody>
              <table v-if="order.user" style="font-size: 30px;">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>
                      <router-link
                        :to="{
                          name: 'admin.users.show',
                          params: { user: order.user.id }
                        }"
                      >
                        {{ order.user.id }}
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.full_name") }}</td>
                    <td>
                      {{ order.user.full_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.phone") }}</td>
                    <td>{{ order.user.phone }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.email") }}</td>
                    <td>{{ order.user.email }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.total_orders") }}</td>
                    <td>{{ order.user.orders_count }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.balance") }}</td>
                    <td>{{ order.user.balance }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.comment") }}</td>
                    <td>
                      <CTextarea
                        v-model="order.user.comment"
                        @input="updateUserComment"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="6">
          <CCard>
            <CCardHeader>{{ $t("admin.orders.payment.title") }}</CCardHeader>
            <CCardBody>
              <table v-if="order.invoice">
                <tbody>
                  <tr>
                    <td>{{ $t("admin.orders.payment.total") }}</td>
                    <td>{{ order.invoice.total }}</td>
                  </tr>
                  <tr v-if="order.invoice.total_previous !== null">
                    <td>{{ $t("admin.orders.payment.total_previous") }}</td>
                    <td>{{ order.invoice.total_previous }}</td>
                  </tr>
                  <tr v-if="order.invoice.total_previous !== null">
                    <td>{{ $t("admin.orders.payment.total_difference") }}</td>
                    <td>{{ order.invoice.total_difference }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.payment.tips") }}</td>
                    <td>{{ order.invoice.tips }}%</td>
                  </tr>
                  <tr v-if="!order.is_pickup">
                    <td>{{ $t("admin.orders.payment.delivery_fee") }}</td>
                    <td>{{ order.invoice.delivery_fee }}</td>
                  </tr>
                  <tr v-if="order.is_pickup">
                    <td>{{ $t("admin.orders.payment.pickup_discount") }}</td>
                    <td>{{ order.invoice.pickup_discount }}%</td>
                  </tr>
                  <tr v-if="order.invoice.is_amex">
                    <td>{{ $t("admin.orders.payment.amex_fee") }}</td>
                    <td>{{ order.invoice.amex_fee }}%</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.payment.method") }}</td>
                    <td>{{ order.invoice.type }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.payment.transaction_id") }}</td>
                    <td>{{ order.invoice.transaction_id }}</td>
                  </tr>
                  <tr v-if="order.invoice.amount_payEdit > 0">
                    <td>{{ $t("admin.orders.payment.amount_payed") }}</td>
                    <td>{{ order.invoice.amount_pay }}</td>
                  </tr>
                  <tr v-if="order.invoice.use_balance > 0" style="color: green">
                    <td>{{ $t("admin.orders.payment.balance_used") }}</td>
                    <td>{{ order.invoice.use_balance }}</td>
                  </tr>
                  <tr v-if="order.invoice.use_balance < 0" style="color: red">
                    <td>{{ $t("admin.orders.payment.debt_paid_off") }}</td>
                    <td>{{ -order.invoice.use_balance }}</td>
                  </tr>
                  <tr v-if="order.invoice.coupon">
                    <td>{{ $t("admin.orders.payment.coupon_used") }}</td>
                    <td>
                      {{ order.invoice.coupon.type }} :
                      {{ order.invoice.coupon.code }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="6">
          <CCard>
            <CCardHeader>For courier</CCardHeader>
            <CCardBody>
              <table v-if="order && order.address">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{{ order.id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.orders.restaurants") }}</td>
                    <td>{{ order.restaurants }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.full_name") }}</td>
                    <td>
                      {{ order.user.full_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.phone") }}</td>
                    <td>{{ order.user.phone }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.street") }}</td>
                    <td>
                      <a
                        :href="
                          '//www.google.com/maps/dir/' +
                            order.restaurantsAddr +
                            '/' +
                            order.address.street +
                            '/'
                        "
                        target="_blank"
                        class="btn_book"
                        >{{ order.address.street }} </a
                      ><CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        class="float-right"
                        v-if="order.status != 'canceled'"
                        @click="openEditAddressModal"
                      >
                        {{ $t("buttons.edit") }}
                      </CButton>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.house") }}</td>
                    <td>{{ order.address.house }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.name_on_card") }}</td>
                    <td>{{ order.address.name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.apartment") }}</td>
                    <td>{{ order.address.apartment }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.floor") }}</td>
                    <td>{{ order.address.floor }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("address.additional_info") }}</td>
                    <td>{{ order.address.comment }}</td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              {{ $t("admin.orders.items") }}

              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="float-right"
                v-if="
                  order.status != 'canceled' &&
                    new Date(order.delivery) > dateMinus7
                "
                @click="openAddOrderDishModal"
              >
                {{ $t("buttons.edit") }}
              </CButton>
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="items"
                :fields="dishes_fields"
                border
                striped
                hover
              >
                <template #id="{item}">
                  <td class="py-2">
                    {{ item.cart_item.dish.id }}
                  </td>
                </template>
                <template #restaurant="{item}">
                  <td class="py-2">
                    {{ item.cart_item.dish.restaurant }}
                  </td>
                </template>
                <template #name="{item}">
                  <td class="py-2">
                    <span
                      :class="{
                        canceled: item.is_canceled,
                        added: !item.is_canceled && item.add_del_by > 0
                      }"
                    >
                      {{ item.cart_item.dish.name }}
                    </span>
                    <div
                      v-if="item.is_canceled"
                      :class="{
                        canceled: item.is_canceled,
                        added: !item.is_canceled && item.add_del_by > 0
                      }"
                      style="font-size: 12px"
                    >
                      <span v-if="item.add_del_by == 1">
                        :Manager
                      </span>
                      <span v-if="item.add_del_by == 2">
                        :Restaurant manager
                      </span>
                    </div>
                  </td>
                </template>
                <template #price_buy="{item}">
                  <td class="py-2">
                    {{ item.cart_item.price_buy }}
                  </td>
                </template>
                <template #price_sell="{item}">
                  <td class="py-2">
                    {{ item.cart_item.price_sell }}
                  </td>
                </template>
                <template #extras="{item}">
                  <td class="py-2">
                    {{ formatExtras(item.cart_item.additions) }}
                  </td>
                </template>
                <template #quantity="{item}">
                  <td class="py-2" style="font-size: 30px;">
                    {{ item.cart_item.quantity }}
                  </td>
                </template>
                <template #comment="{item}">
                  <td class="py-2">
                    {{ item.cart_item.comment }}
                  </td>
                </template>
                <template #show_actions="{item}">
                  <td class="py-2 text-center">
                    <CButton
                      color="danger"
                      square
                      size="sm"
                      class="mr-1"
                      @click="deleteDish(item.id)"
                      v-if="
                        !item.is_canceled &&
                          order.status != 'canceled' &&
                          new Date(order.delivery) > dateMinus7
                      "
                    >
                      <CIcon name="cilTrash"></CIcon>
                    </CButton>
                  </td>
                </template>
              </CDataTable>

              <AddOrderDish
                :subOrders="order.sub_orders"
                :order="order.id"
                :show="isAddOrderDishModalOpened"
                @closed="closeAddOrderDishModal"
                @stored="getData"
              />

              <EditAddress
                v-if="order.user"
                :subOrders="order.sub_orders"
                :order="order.id"
                :user="order.user.id"
                :show="isEditAddressOpened"
                @closed="closeEditAddressModal"
                @updated="getData"
              />

              <ReplaceSubOrderDish
                :subOrder="activeSubOrderId"
                :restaurant="activeRestaurantId"
                @closed="closeReplaceSubOrderDishModal"
                @replaced="getData"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm="12">
          <CDataTable
            :items="order.sub_orders"
            :fields="confirmation_fields"
            border
            striped
            hover
          >
            <template #restaurant_manager_comment="{item}">
              <td class="py-2">
                <span v-if="item.restaurant_manager_comment !== null">
                  {{ item.restaurant_manager_comment }}
                </span>
              </td>
            </template>

            <template #show_actions="{item}">
              <td class="py-2 text-center">
                <CButton
                  color="primary"
                  square
                  size="sm"
                  class="mr-1"
                  @click="updateStatus(item.id, 'delivery')"
                  v-if="item.status === 'cooking'"
                >
                  <CIcon name="cilTruck" />
                </CButton>

                <CButton
                  color="primary"
                  square
                  size="sm"
                  class="mr-1"
                  @click="updateStatus(item.id, 'confirmation')"
                  v-if="item.status === 'in_queue'"
                >
                  <CIcon name="cilSend" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import debounce from "lodash.debounce";
import AddOrderDish from "@/components/admin/AddOrderDish";
import ReplaceSubOrderDish from "@/components/admin/ReplaceSubOrderDish";
import EditAddress from "@/components/admin/EditAddress";

export default {
  components: {
    AddOrderDish,
    ReplaceSubOrderDish,
    EditAddress
  },

  data() {
    return {
      isLoading: false,
      dateMinus7: "",
      isUpdating: false,
      isAddOrderDishModalOpened: false,
      isEditAddressOpened: false,
      showReplaceDishModal: false,
      activeSubOrderId: null,
      activeRestaurantId: null,
      order: {},
      restaurantsAddr: null,
      dishes_fields: [
        {
          key: "id",
          label: this.$t("admin.orders.dish_id"),
          _style: "width: 100px"
        },
        { key: "restaurant", label: this.$t("admin.orders.restaurant") },
        { key: "name", label: this.$t("admin.name") },
        { key: "price_buy", label: this.$t("admin.price_buy") },
        { key: "price_sell", label: this.$t("admin.price_sell") },
        { key: "extras", label: this.$t("admin.orders.extras") },
        { key: "quantity", label: this.$t("admin.orders.quantity") },
        { key: "comment", label: this.$t("admin.orders.comment") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ],
      confirmation_fields: [
        {
          key: "id",
          label: this.$t("admin.orders.sub_order_id"),
          _style: "width: 100px"
        },
        { key: "restaurant", label: this.$t("admin.orders.restaurant") },
        {
          key: "cooking_time",
          label: this.$t("admin.orders.cooking_time"),
          _style: "width: 200px"
        },
        {
          key: "restaurant_manager_comment",
          label: this.$t("admin.orders.restaurant_manager_comment")
        },
        {
          key: "status",
          label: this.$t("admin.orders.status"),
          _style: "width: 200px"
        },
        {
          key: "updated_at",
          label: this.$t("admin.orders.updated_at"),
          _style: "width: 200px"
        },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ]
    };
  },

  created() {
    this.getData();
  },

  computed: {
    items() {
      if (!this.order.sub_orders) return [];

      return Object.keys(this.order.sub_orders).flatMap(
        key => this.order.sub_orders[key].items
      );
    }
  },

  methods: {
    getData() {
      this.isLoading = true;
      let date7 = new Date();
      date7.setDate(date7.getDate() - 7);
      this.dateMinus7 = date7;

      this.$http
        .get(`/api/admin/orders/${this.$route.params.order}`)
        .then(response => {
          this.order = response.data.data;

          this.isLoading = false;
        });
    },
    async updateStatus(id, status) {
      this.isLoading = true;
      await this.$http.patch(`/api/admin/sub-orders/${id}`, { status });
      await this.getData();
    },
    updateAddressComment: debounce(function(comment) {
      this.isUpdating = true;

      this.$http
        .patch(`/api/admin/orders/${this.order.id}`, { address: { comment } })
        .then(() => (this.isUpdating = false));
    }, 500),
    updateUserComment: debounce(function(comment) {
      this.isUpdating = true;

      this.$http
        .patch(`/api/admin/users/${this.order.user.id}`, { comment })
        .then(() => (this.isUpdating = false));
    }, 500),
    formatExtras(items) {
      return items
        .map(item => `${item.type}: ${item.name} + €${item.price_sell}`)
        .join(", ");
    },
    openAddOrderDishModal() {
      this.isAddOrderDishModalOpened = true;
    },
    closeAddOrderDishModal() {
      this.isAddOrderDishModalOpened = false;
    },
    openEditAddressModal() {
      this.isEditAddressOpened = true;
    },
    closeEditAddressModal() {
      this.isEditAddressOpened = false;
    },

    closeReplaceSubOrderDishModal() {
      this.activeSubOrderId = null;
      this.activeRestaurantId = null;
    },
    async deleteDish(id) {
      if (confirm("Are you sure you want to cancel this dish?")) {
        this.isLoading = true;
        await this.$http.delete(`/api/admin/sub-order-items/${id}`);
        await this.getData();
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.orders.show.title")
    };
  }
};
</script>

<style scoped>
table {
  width: 100%;
}

.spinner {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.canceled {
  color: #ff3939;
}
.added {
  color: #d139ff;
}
</style>
