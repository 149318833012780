<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader>{{ $t("admin.orders.user") }}</CCardHeader>
          <CCardBody>
            <table v-if="user">
              <tbody>
                <tr>
                  <td>ID</td>
                  <td>{{ user.id }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.full_name") }}</td>
                  <td>
                    {{ user.full_name }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.phone") }}</td>
                  <td>{{ user.phone }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.email") }}</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.total_orders") }}</td>
                  <td>{{ user.orders_count }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.balance") }}</td>
                  <td>{{ user.balance }}</td>
                </tr>
                <tr>
                  <td>{{ $t("admin.users.comment") }}</td>
                  <td>
                    <CTextarea
                      v-model="user.comment"
                      @input="updateUserComment"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader>{{ $t("address.additional_info") }}</CCardHeader>
          <CCardBody>
            <div class="order__tips">
              <span class="order__tips-text">{{ $t("cart.tips") }}</span>
              <div class="vidget__body-2">
                <span
                  :class="['order__tips-item', { active: form.tips === 0.05 }]"
                  @click="setTips(0.05)"
                >
                  5%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.07 }]"
                  @click="setTips(0.07)"
                >
                  7%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.1 }]"
                  @click="setTips(0.1)"
                >
                  10%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0.15 }]"
                  @click="setTips(0.15)"
                >
                  15%
                </span>
                <span
                  :class="['order__tips-item', { active: form.tips === 0 }]"
                  @click="setTips(0)"
                  >{{ $t("cart.no_tips") }}
                </span>
              </div>
            </div>
            <div style="padding:10px"></div>
            <div>
              <span id="text-address" class="nav-text">
                {{ $t("delivery_address") }} </span
              ><br />
              <label
                v-for="address in user.addresses"
                :key="address.id"
                class="radio"
              >
                <div class="radio__address">
                  <input
                    :checked="form.address_id === address.id"
                    class="radio__real"
                    name="term"
                    type="radio"
                    @click="form.address_id = address.id"
                  />
                  <span class="address__text">
                    {{ $t("address.categories." + address.category) }}
                  </span>
                  <span class="address__text2">{{ address.street }}</span>
                </div>
              </label>
            </div>
            <div class="order__tips"></div>
            <div class="order__tips">
              <span id="delivery-text" class="nav-text">
                {{ $t("delivery_time") }}
              </span>
              <div class="delivery-data">
                <span class="day-text">{{ deliveryDay }}</span>
                <span class="day-time">{{ deliveryTime }}</span>
                <button id="btn-date1" @click="toggleSelectDelivery">
                  {{ $t("cart.select_date_and_hour") }}
                </button>
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CAlert color="danger" :fade="false" v-if="Object.entries(errors).length">
      {{ errors.message }}
      <ul>
        <li v-for="(error, field) in errors.errors" :key="field">
          {{ error[0] }}
        </li>
      </ul>
    </CAlert>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            {{ $t("admin.orders.items") }}
            <span style="font-weight: bold;">(Total: {{ (cart.total + cart.total * form.tips).toFixed(2)}} &euro;)</span>
            <CButton
              color="primary"
              variant="outline"
              square
              size="sm"
              class="float-right"
              @click="openAddOrderDishModal"
            >
              {{ $t("buttons.add") }}
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              :items="items"
              :fields="dishes_fields"
              border
              striped
              hover
            >
              <template #id="{item}">
                <td class="py-2">
                  {{ item.dish.id }}
                </td>
              </template>
              <template #restaurant="{item}">
                <td class="py-2">
                  {{ item.dish.restaurant }}
                </td>
              </template>
              <template #name="{item}">
                <td class="py-2">
                  <span :class="{ canceled: item.is_canceled }">
                    {{ item.dish.name }}
                  </span>
                </td>
              </template>
              <template #price_buy="{item}">
                <td class="py-2">
                  {{ item.dish.price_buy }}
                </td>
              </template>
              <template #price_sell="{item}">
                <td class="py-2">
                  {{ item.dish.price_sell }}
                </td>
              </template>
              <template #extras="{item}">
                <td class="py-2">
                  {{ formatExtras(item.additions) }}
                </td>
              </template>
              <template #quantity="{item}">
                <td class="py-2">
                  {{ item.quantity }}
                </td>
              </template>
              <template #comment="{item}">
                <td class="py-2">
                  {{ item.comment }}
                </td>
              </template>
              <template #show_actions="{item}">
                <td class="py-2 text-center">
                  <CButton
                    color="danger"
                    square
                    size="sm"
                    class="mr-1"
                    @click="deleteDish(item.id)"
                    v-if="!item.is_canceled"
                  >
                    <CIcon name="cilTrash"></CIcon>
                  </CButton>
                </td>
              </template>

            </CDataTable>

            <AddOrderDish
              :show="isAddOrderDishModalOpened"
              @closed="closeAddOrderDishModal"
              @stored="addOrder"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" v-if="order">
        <CDataTable
          :items="order.sub_orders"
          :fields="confirmation_fields"
          border
          striped
          hover
        >
          <template #restaurant_manager_comment="{item}">
            <td class="py-2">
              <span v-if="item.restaurant_manager_comment !== null">
                {{ item.restaurant_manager_comment }}
              </span>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <CButton
      color="primary"
      variant="outline"
      square
      size="sm"
      class="float-right"
      @click="createOrder"
    >
      {{ $t("buttons.create") }}
    </CButton>
    <DeliveryPopup
      v-show="isSelectDeliveryOpened"
      ref="delivery_popup"
      @close-popup="toggleSelectDelivery"
      @set-deliver-from="form.deliver_from = $event"
      @set-deliver-to="handleSetDeliverTo"
      @set-period="form.period = $event"
      @set-is-tomorrow="form.is_tomorrow = $event"
    />
  </div>
</template>

<script>
import debounce from "lodash.debounce";
import AddOrderDish from "@/components/admin/AddOrderDish";
import DeliveryPopup from "@/components/DeliveryPopup";
import { formatInterval } from "@/helpers";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AddOrderDish,
    DeliveryPopup
  },
  data() {
    return {
      isLoading: true,
      errors: [],
      user: {},
      order: {},
      restaurants: [],
      dishes: [],
      restaurant: null,
      isSelectDeliveryOpened: false,
      isTomorrowOnlyAlertOpened: false,
      isAddOrderDishModalOpened: false,
      activeSubOrderId: null,
      activeRestaurantId: null,
      dish: {},
      form: {
        user_id: null,
        comment: "",
        has_cutlery: false,
        deliver_from: null,
        is_tomorrow: false,
        deliver_to: null,
        period: null,
        address_id: null,
        payment_type: "cash",
        tips: 0.07
      },
      dishes_fields: [
        {
          key: "id",
          label: this.$t("admin.orders.dish_id"),
          _style: "width: 100px"
        },
        { key: "restaurant", label: this.$t("admin.orders.restaurant") },
        { key: "name", label: this.$t("admin.name") },
        { key: "price_buy", label: this.$t("admin.price_buy") },
        { key: "price_sell", label: this.$t("admin.price_sell") },
        { key: "extras", label: this.$t("admin.orders.extras") },
        { key: "quantity", label: this.$t("admin.orders.quantity") },
        { key: "comment", label: this.$t("admin.orders.comment") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 130px"
        }
      ],
      confirmation_fields: [
        {
          key: "id",
          label: this.$t("admin.orders.sub_order_id"),
          _style: "width: 100px"
        },
        { key: "restaurant", label: this.$t("admin.orders.restaurant") },
        {
          key: "cooking_time",
          label: this.$t("admin.orders.cooking_time"),
          _style: "width: 200px"
        },
        {
          key: "restaurant_manager_comment",
          label: this.$t("admin.orders.restaurant_manager_comment")
        },
        {
          key: "status",
          label: this.$t("admin.orders.status"),
          _style: "width: 200px"
        },
        {
          key: "updated_at",
          label: this.$t("admin.orders.updated_at"),
          _style: "width: 200px"
        }
      ]
    };
  },
  mounted() {
    this.isTomorrowOnlyAlertOpened =
      this.cart.delivery_intervals.today.find(
        interval => interval.available
      ) === undefined && this.cart.delivery_intervals.tomorrow.length > 0;
  },
  computed: {
    ...mapState("cart", ["cart"]),
    items() {
      if (!this.order.sub_orders) return [];

      return Object.keys(this.order.sub_orders).flatMap(
        key => this.order.sub_orders[key].items
      );
    },
    deliveryDay() {
      return this.form.is_tomorrow
        ? this.$t("delivery.tomorrow")
        : this.$t("delivery.today");
    },
    deliveryTime() {
      return this.form.deliver_from == null || this.form.deliver_to == null
        ? "—"
        : formatInterval(this.form.deliver_from, this.form.deliver_to);
    }
  },
  created() {
    this.getUser();
  },
  methods: {
    ...mapActions("cart", ["getCart", "deleteCart", "setDeliverTo"]),
    getUser() {
      this.isLoading = true;
      this.$http
        .get(`/api/admin/users/${this.$route.params.user}`)
        .then(async response => {
          await this.emptyCart();
          this.user = response.data.data;
          this.order = {
            user: this.user,
            sub_orders: [],
            restaurants: "",
            address: {},
            deliver_from: "",
            deliver_to: "",
            invoice: {}
          };
          this.setDefaultAddress();
          this.isLoading = false;
        });
    },
    setTips(tip) {
      this.form.tips = tip;
      this.order.invoice.tips = tip;
    },
    formatExtras(items) {
      return items
        .map(item => `${item.type}: ${item.name} + €${item.price_sell}`)
        .join(", ");
    },
    addOrder(form, rest, rest_id, addit, dish) {
      form.additions = addit;
      form.dish = dish;
      let subOrders = null;
      if (this.order.sub_orders.length > 0) {
        subOrders = this.order.sub_orders.find(
          subOrder => subOrder.restaurant_id === rest_id
        );
      }
      if (subOrders) {
        subOrders.items.push(form);
      } else {
        const subOrder = {
          id: "new",
          restaurant_id: rest_id,
          restaurant: rest.label,
          cooking_time: rest.cooking_time,
          status: "in_queue",
          updated_at: "",
          items: [form]
        };
        this.order.sub_orders.push(subOrder);
      }
      this.getCart().then(() => {
        this.form.deliver_to = null;
        this.form.deliver_from = null;
        this.form.is_tomorrow = false;
      });
    },
    toggleSelectDelivery() {
      if(!this.isSelectDeliveryOpened) this.$refs.delivery_popup.$emit('prepare');

      this.isSelectDeliveryOpened = !this.isSelectDeliveryOpened;
    },
    updateUserComment: debounce(function(comment) {
      this.isUpdating = true;

      this.$http
        .patch(`/api/admin/users/${this.user.id}`, { comment })
        .then(() => (this.isUpdating = false));
    }, 500),
    formatAdditions(additions) {
      return additions.map(addition => {
        return { label: addition.name, value: addition.id.toString() };
      });
    },
    openAddOrderDishModal() {
      this.isAddOrderDishModalOpened = true;
    },
    closeAddOrderDishModal() {
      this.isAddOrderDishModalOpened = false;
    },
    setDefaultAddress() {
      if (this.user.addresses.length > 0 && this.form.address_id === null) {
        this.form.address_id = this.user.addresses[0].id;
      }
    },
    async deleteDish(id) {
      if (confirm("Are you sure you want to cancel this dish?")) {
        this.isLoading = true;
        await this.$http.delete(`/api/cart-items/${id}`);
        this.order.sub_orders.forEach(function(sub_order) {
          sub_order.items = sub_order.items.filter(function(item) {
            return item.id != id;
          });
        });
        this.order.sub_orders = this.order.sub_orders.filter(function(
          sub_order
        ) {
          return sub_order.items.length != 0;
        });
        this.isLoading = false;
      }
    },
    handleSetDeliverTo(value) {
      if (value != null) {
        this.form.deliver_to = value;
        this.setDeliverTo(value);
      }
    },
    createOrder() {
      if (!this.form.address_id) {
        this.errors = {
          errors: { address: ["Choose an address."] },
          message: "The given data was invalid."
        };
        return;
      }
      if (this.form.deliver_from == null || this.form.deliver_to == null) {
        this.errors = {
          errors: { delivery: ["Select delivery time."] },
          message: "The given data was invalid."
        };
        this.$refs.delivery_popup.prepare();
        this.toggleSelectDelivery();
        return;
      }
      this.form.user_id = this.order.user.id;
      this.$http
        .post("/api/user/orders", this.form)
        .then(async response => {
          const order_id = response.data.data.id;

          await this.deleteCart();

          await this.$router.push({
            name: "admin.orders.show",
            params: { order: order_id }
          });
        })
        .catch(error => (this.errors = error.response.data));
    },
    emptyCart() {
      if (this.cart.items.length > 0) {
        this.deleteCart().then(() => {
          location.reload();
        });
      }
    }
  }
};
</script>
