<template>
  <CCard>
    <div class="loader" v-if="isLoading">
      <CSpinner color="primary" style="width:4rem; height:4rem;" grow />
    </div>

    <CCardHeader>
      {{ $t("admin.users.show.title") }}
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="4">
          <CCard>
            <CCardHeader>
              {{ $t("admin.users.show.info") }}

              <CButton
                class="float-right"
                color="primary"
                size="sm"
                square
                @click="showEditInfoModal = true"
              >
                <CIcon name="cilPencil" />
              </CButton>

              <EditUserInfo
                :show="showEditInfoModal"
                :user="user"
                @closed="showEditInfoModal = false"
                @updated="getUser"
              />
            </CCardHeader>
            <CCardBody>
              <table v-if="user">
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{{ user.id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.full_name") }}</td>
                    <td>{{ user.full_name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.phone") }}</td>
                    <td>{{ user.phone }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.email") }}</td>
                    <td>{{ user.email }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.total_orders") }}</td>
                    <td>{{ user.orders_count }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.last_order_at") }}</td>
                    <td>{{ user.last_ordered_at }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.comment") }}</td>
                    <td>{{ user.comment }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("admin.users.balance") }}</td>
                    <td>
                      {{ user.balance }}
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        class="ml-2"
                        @click="showBalanceModal = true"
                      >
                        <CIcon name="cilPencil" />
                      </CButton>

                      <AlterUserBalance
                        :user="user"
                        :show="showBalanceModal"
                        @closed="showBalanceModal = false"
                        @updated="getUser"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm="8">
          <CCard>
            <CCardHeader>
              {{ $t("admin.users.show.balance_history") }}
            </CCardHeader>
            <CCardBody>
              <CDataTable
                :items="user.balances"
                :items-per-page="4"
                :fields="balances_fields"
                :noItemsView="{
                  noResults: $t('admin.no_results'),
                  noItems: $t('admin.no_items')
                }"
                border
                striped
                hover
                pagination
              >
                <template #method="{item}">
                  <td class="py-2">
                    <span
                      v-if="
                        item.order_id != null &&
                          item.method === 'cash' &&
                          item.amount > 0
                      "
                      >Payement (Covering the debt)</span
                    >
                    <span
                      v-else-if="
                        item.order_id != null &&
                          item.method === 'cash' &&
                          item.amount < 0
                      "
                      >Payment with balance</span
                    >
                    <span
                      v-else-if="
                        item.order_id != null &&
                          item.method === 'refund' &&
                          item.amount > 0
                      "
                      >Canceled item</span
                    >
                    <span
                      v-else-if="
                        item.order_id != null &&
                          item.method === 'refund' &&
                          item.amount < 0
                      "
                      >Added item</span
                    >
                    <span v-else>{{ item.method }}</span>
                  </td>
                </template>
                <template #amount="{item}">
                  <td class="py-2">
                    {{ item.amount }}
                  </td>
                </template>
                <template #created_at="{item}">
                  <td class="py-2">
                    {{ item.created_at }}
                  </td>
                </template>
                <template #order_id="{item}">
                  <td class="py-2">
                    <a :href="'/admin/orders/' + item.order_id">{{
                      item.order_id
                    }}</a>
                  </td>
                </template>
                <template #comment="{item}">
                  <td class="py-2">
                    {{ item.comment }}
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>{{ $t("admin.users.show.addresses") }}</CCardHeader>
            <CCardBody>
              <CDataTable
                :loading="isLoading"
                :items="user.addresses"
                :items-per-page="5"
                :fields="addresses_fields"
                :noItemsView="{
                  noResults: $t('admin.no_results'),
                  noItems: $t('admin.no_items')
                }"
                border
                striped
                hover
                pagination
              >
                <template #show_actions="{item}">
                  <td class="py-2 text-center">
                    <CButton
                      class="mr-1"
                      color="danger"
                      size="sm"
                      square
                      @click="deleteAddress(item.id)"
                    >
                      <CIcon name="cilTrash"></CIcon>
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>{{ $t("admin.users.show.orders") }}</CCardHeader>
            <CCardBody>
              <CDataTable
                :loading="isLoading"
                :items="orders"
                :fields="orders_fields"
                :noItemsView="{
                  noResults: $t('admin.no_results'),
                  noItems: $t('admin.no_items')
                }"
                border
                striped
                hover
              >
                <template #show_payment="{item}">
                  <td class="py-2">
                    {{
                      $t("admin.orders.payment.info", {
                        total: item.invoice.total,
                        tips: item.invoice.tips,
                        method: item.invoice.type,
                        trans_id: item.invoice.transaction_id,
                        delivery_fee: item.invoice.delivery_fee,
                        pickup_discount: item.invoice.pickup_discount
                      })
                    }}
                  </td>
                </template>

                <template #show_address="{item}">
                  <td class="py-2">
                    {{ item.address.short }}
                  </td>
                </template>

                <template #processed_at="{item}">
                  <td class="py-2">
                    {{ item.invoice.processed_at }}
                  </td>
                </template>

                <template #show_actions="{item}">
                  <td class="py-2 text-center">
                    <CButton
                      color="primary"
                      square
                      size="sm"
                      class="mr-1"
                      :to="{
                        name: 'admin.orders.show',
                        params: { order: item.id }
                      }"
                    >
                      <CIcon name="cilPencil" />
                    </CButton>
                  </td>
                </template>
              </CDataTable>
              <CPagination
                :activePage="pagination.current_page"
                :pages="pagination.last_page"
                :limit="100"
                align="center"
                @update:activePage="getOrders($event)"
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import EditUserInfo from "@/components/admin/EditUserInfo";
import AlterUserBalance from "@/components/admin/AlterUserBalance";

export default {
  components: {
    EditUserInfo,
    AlterUserBalance
  },

  data() {
    return {
      isLoading: false,
      showBalanceModal: false,
      showEditInfoModal: false,
      user: {},
      orders: [],
      pagination: {},
      balances_fields: [
        { key: "method", label: this.$t("balance.method") },
        { key: "amount", label: this.$t("balance.amount") },
        { key: "created_at", label: this.$t("balance.created_at") },
        { key: "order_id", label: this.$t("balance.order_id") },
        { key: "comment", label: this.$t("balance.comment") }
      ],
      addresses_fields: [
        { key: "name", label: this.$t("address.name_on_card") },
        { key: "street", label: this.$t("address.street") },
        { key: "house", label: this.$t("address.house") },
        { key: "floor", label: this.$t("address.floor") },
        { key: "apartment", label: this.$t("address.apartment") },
        { key: "comment", label: this.$t("address.additional_info") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 180px"
        }
      ],
      orders_fields: [
        {
          key: "id",
          label: this.$t("admin.orders.dish_id"),
          _style: "width: 100px"
        },
        { key: "restaurants", label: this.$t("admin.orders.restaurants") },
        { key: "show_payment", label: this.$t("admin.orders.payment.title") },
        { key: "show_address", label: this.$t("admin.orders.address") },
        { key: "processed_at", label: this.$t("admin.orders.processed_at") },
        { key: "deliver_from", label: this.$t("admin.orders.deliver_from") },
        { key: "deliver_to", label: this.$t("admin.orders.deliver_to") },
        { key: "status", label: this.$t("admin.orders.status") },
        { key: "couriers", label: this.$t("admin.orders.couriers") },
        {
          key: "show_actions",
          label: this.$t("admin.actions"),
          _style: "width: 100px"
        }
      ]
    };
  },

  created() {
    this.getUser();
    this.getOrders();
  },

  methods: {
    getUser() {
      this.isLoading = true;

      this.$http
        .get(`/api/admin/users/${this.$route.params.user}`)
        .then(response => {
          this.user = response.data.data;

          this.isLoading = false;
        });
    },
    getOrders(page = 1) {
      this.isLoading = true;

      this.$http
        .get(`/api/admin/users/${this.$route.params.user}/orders`, {
          params: { page }
        })
        .then(response => {
          this.orders = response.data.data;
          this.pagination = response.data.meta;

          this.isLoading = false;
        });
    },
    deleteAddress(id) {
      if (confirm("Are you sure you want to delete this address?")) {
        this.isLoading = true;
        this.$http
          .delete(`/api/admin/users/${this.$route.params.user}/addresses/${id}`)
          .catch(error => {
            alert(error.response.data.message);
          })
          .finally(() => this.getUser());
      }
    }
  },

  metaInfo() {
    return {
      title: this.$t("admin.users.show.title")
    };
  }
};
</script>

<style scoped>
table {
  width: 100%;
}
</style>
